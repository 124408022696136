@font-face {
	font-family: 'TT Supermolot Neue Variable';
	src: url(../fonts/tt_supermolot_neue_black.woff2) format('woff2');
	font-weight: 900;
}
@font-face {
	font-family: 'TT Supermolot Neue Variable';
	src: url(../fonts/tt_supermolot_neue_bold.woff2) format('woff2');
	font-weight: 700;
}
@font-face {
	font-family: 'TT Supermolot Neue Variable';
	src: url(../fonts/tt_supermolot_neue_demibold.woff2) format('woff2');
	font-weight: 600;
}
@font-face {
	font-family: 'TT Supermolot Neue Variable';
	src: url(../fonts/tt_supermolot_neue_light.woff2) format('woff2');
	font-weight: 300;
}
@font-face {
	font-family: 'TT Supermolot Neue Variable';
	src: url(../fonts/tt_supermolot_neue_medium.woff2) format('woff2');
	font-weight: 500;
}
@font-face {
	font-family: 'TT Supermolot Neue Variable';
	src: url(../fonts/tt_supermolot_neue_regular.woff2) format('woff2');
	font-weight: 400;
}